import { Button } from '@plusplusminus/plusplusdash'
import { gql, useMutation } from '@apollo/client'
import { useCallback } from 'react'

const CREATE_APPLICATION = gql`
  mutation createApplication($input: CreateApplicationInput!) {
    createApplication(input: $input) {
      id
    }
  }
`
interface RegisterButtonProps {
  template: string
  title: string
  className?: string
  refetch?: () => void
}

export const RegisterButton: React.FC<RegisterButtonProps> = (props) => {
  const [createApplication, { loading }] = useMutation(CREATE_APPLICATION)

  const onRegister = useCallback(async () => {
    createApplication({
      variables: {
        input: {
          role: props.template
        }
      }
    }).then(() => {
      if (props.refetch) {
        props.refetch()
      }
    })
  }, [props])
  return (
    <Button
      size="xl"
      disabled={loading}
      variant={'primary'}
      children={!loading ? props.title : 'Loading'}
      className={props.className}
      onClick={onRegister}
    ></Button>
  )
}
