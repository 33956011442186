import { Button } from '@plusplusminus/plusplusdash'
import { Link } from '@reach/router'
import React from 'react'

interface Application {
  id: string
  template: { name: string }
  status: 'pending' | 'rejected' | 'approved' | 'review'
  owner: {
    firstName: string
    lastName: string
  }
}
interface ApplicationListItems {
  items: Array<Application>
  newTab?: boolean
}

const statusColor = {
  pending: 'yellow',
  rejected: 'red',
  approved: 'green',
  review: 'blue'
}

export const ApplicationList: React.FC<ApplicationListItems> = ({ items, newTab = false }) => {
  return (
    <div className="sm:block">
      <div className="align-middle inline-block min-w-full border-b border-gray-200">
        <table className="min-w-full">
          <thead>
            <tr className="border-t border-gray-200">
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <span className="lg:pl-2">Applications</span>
              </th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="pr-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-100">
            {items.map((item) => {
              const linkprops = newTab ? { href: `/application/${item.id}`, target: '_blank' } : {}
              const LinkComponent = newTab ? (
                <a {...linkprops} className="truncate hover:text-gray-600">
                  <span>{item.template.name}</span>
                </a>
              ) : (
                <Link to={`/application/${item.id}`} className="truncate hover:text-gray-600">
                  <span>{item.template.name}</span>
                </Link>
              )

              return (
                <tr>
                  <td className="px-6 py-3 max-w-0 w-full whitespace-nowrap text-sm font-medium text-gray-900">
                    <div className="flex items-center space-x-3 lg:pl-2">
                      <div
                        className={`flex-shrink-0 w-2.5 h-2.5 rounded-full bg-${statusColor[item.status]}-600`}
                        aria-hidden="true"
                      ></div>
                      {LinkComponent}
                    </div>
                  </td>
                  <td className="px-6 py-3 text-sm text-gray-500 font-medium">
                    <div className="flex items-center space-x-2">
                      <span className="flex-shrink-0 text-xs leading-5 font-medium">{item.status}</span>
                    </div>
                  </td>
                  <td className="px-6 py-3 text-sm text-gray-500 font-medium"></td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
