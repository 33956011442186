export const uploadApplicationFile = async (
  applicationId: string,
  requirementId: string,
  file: any
): Promise<boolean> => {
  const formdata = new FormData()
  formdata.append('file', file, file.filename)

  formdata.append('applicationId', applicationId)
  formdata.append('requirementId', requirementId)

  const requestOptions = {
    method: 'POST',
    body: formdata
  }

  await fetch(`${process.env.REACT_APP_API}/applications/files`, requestOptions)

  return true
}
