import { Box, Button, Input } from '@plusplusminus/plusplusdash'
import { useForm } from 'react-hook-form'
import { InputValidator } from '../../../services/validate'

const form = [
  {
    label: 'First Name',
    name: 'firstName',
    type: 'text',
    placeholder: 'First Name',
    options: {
      required: true
    }
  },
  {
    label: 'Last Name',
    name: 'lastName',
    placeholder: 'Last Name',
    options: {
      required: true
    },
    type: 'text'
  },
  {
    label: 'Cellphone',
    name: 'cellphone',
    placeholder: 'Cellphone',
    options: {
      required: true,
      validate: {
        manual: (value: string) => {
          return new InputValidator(value).validateCellphone()
        }
      }
    },
    type: 'text'
  },
  {
    label: 'Email',
    name: 'email',
    placeholder: 'Email',
    options: {
      required: true,
      validate: {
        manual: (value: string) => {
          return new InputValidator(value).validateEmail()
        }
      }
    },
    type: 'email'
  },
  {
    label: 'ID Number',
    name: 'idnumber',
    placeholder: 'ID Number',
    options: {
      required: true,
      validate: {
        manual: (value: string) => {
          return new InputValidator(value).validateIdNumber()
        }
      }
    },

    type: 'text'
  },
  {
    label: 'Register as a Ward Candidate',
    name: 'candidate',
    placeholder: '',
    options: {},
    type: 'checkbox'
  }
]

interface Props {
  onSubmit?: (data: any) => void
}

export const RegistrationForm: React.FC<Props> = (props) => {
  const { register, handleSubmit, errors } = useForm({})

  const onRegister = async (data: any) => {
    if (props.onSubmit) {
      props.onSubmit(data)
    }
  }

  return (
    <form
      action="#"
      autoComplete="no"
      onSubmit={handleSubmit(onRegister)}
      className="mt-6 grid grid-cols-2 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
    >
      {form.map((field) => {
        if (field.type === 'checkbox') {
          return (
            <Box className="flex items-center col-span-2">
              <input
                id={field.name}
                name={field.name}
                type="checkbox"
                ref={register(field.options)}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label htmlFor={field.name} className="ml-2 block text-sm text-gray-900">
                {field.label}
              </label>
            </Box>
          )
        }

        return (
          <Box className="col-span-2 sm:col-span-2">
            <label htmlFor={field.name} className="block text-sm font-medium text-gray-900 mb-1">
              {field.label}
            </label>
            <Input
              as="input"
              variant="standard"
              width="full"
              name={field.name}
              type={field.type}
              placeholder={field.placeholder}
              ref={register(field.options)}
              className="mb-2 w-full"
            />
            {errors[field.name] && errors[field.name].type === 'required' ? (
              <div className="text-sm text-red-300">Required Field</div>
            ) : null}
            {errors[field.name] && errors[field.name].type === 'manual' ? (
              <div className="text-sm text-red-300">Invalid Field</div>
            ) : null}
          </Box>
        )
      })}

      <div className="sm:col-span-2 sm:flex sm:justify-start">
        <Button size="xl" type="submit" colorScheme="green" variant="primary">
          Submit
        </Button>
      </div>
    </form>
  )
}
