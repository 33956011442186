import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

export interface StructureProps {
  type: string
  code: string
  structure: string
  shouldFilter?: boolean
}

export interface Location {
  id: number
  name: string
  code: string
}

export interface Candidate {
  id: string
  isArchived: boolean
  location: Location
  petition: { id: string; submissionCount: number }
}

interface StructureResults {
  locations: Array<Location>
  submitting: boolean
}

export const useStructure = ({ type, code, structure, shouldFilter = true }: StructureProps): StructureResults => {
  // auth mutation here
  const { loading, data } = useQuery(GET_LOCATIONS, {
    variables: {
      input: {
        filterByType: type,
        filterByCode: code,
        type: structure,
        dataset: '2020'
      }
    }
  })

  if (structure === 'PROVINCE') {
    return {
      locations: data?.locations.filter((l: any) => l.code === 'GP'),
      submitting: loading
    }
  }

  if (structure === 'MUNICIPALITY') {
    return {
      locations: shouldFilter
        ? data?.locations.filter((l: any) => ['TSH'].includes(l.code)) ?? []
        : data?.locations,
      submitting: loading
    }
  }

  return {
    locations: data?.locations.filter((l:any) => ['79900096'].includes(l.code)) ?? [],
    submitting: loading
  }
}

const GET_LOCATIONS = gql`
  query getLocations($input: GetLocationInput!) {
    locations: getLocations(input: $input) {
      id
      name
      code
      type
    }
  }
`
