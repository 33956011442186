import { Link, RouteComponentProps } from '@reach/router'
import { LayoutBox } from '../../components/LayoutBox'

export const Registration: React.FC<RouteComponentProps> = () => {
  return (
    <LayoutBox
      title="Register with ActionSA to participate in our Candidate Elections - a direct democracy process that allows YOU to choose who will represent us on the ballot in the Local Government Elections."
      description={
        'By registering and voting in this process, it will be you, and not a political party, that chooses ActionSA’s candidates for Ward Councillor.'
      }
      formTitle="Select option:"
      showLogo={true}
    >
      <div className="sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-2">
        <div className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
          <div className="p-6">
            <h2 className="text-lg leading-6 font-medium text-gray-900">Voter</h2>
            <p className="mt-4 text-sm text-gray-500">Register to vote in the ActionSA Candidate Elections.</p>

            <Link
              to="/register/voter"
              className="mt-8 block w-full bg-green border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-green-700"
            >
              Register as Voter
            </Link>
          </div>
        </div>

        <div className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
          <div className="p-6">
            <h2 className="text-lg leading-6 font-medium text-gray-900">Candidate</h2>
            <p className="mt-4 text-sm text-gray-500">
              Register as a prospective candidate for the ActionSA Candidate Elections.
            </p>

            <Link
              to="/register/candidate"
              className="mt-8 block w-full bg-purple-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-purple-700"
            >
              Register as Candidate
            </Link>
          </div>
        </div>
        <div className="mt-4 border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
          <div className="p-6">
            <h2 className="mt-2 text-lg leading-6 font-medium text-gray-900">Already Registered?</h2>
            <p className="mt-4 text-sm text-gray-500">Login to view your application.</p>
            <Link
              to="/login"
              className="mt-8 block w-half bg-green border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-green-700"
            >
              Login
            </Link>
          </div>
        </div>
      </div>

      <dl className="mt-8">
        <div className="mb-4">
          <dt className="text-lg leading-6 font-medium text-gray-900">What are ActionSA’s Candidate Elections?</dt>
          <dd className="text-base text-gray-500">
            <p className="mb-2">
              Our Candidate Elections are similar to primary elections, which takes place in countries like the United
              States. It is a process whereby a political party allows ordinary citizens to decide who should represent
              them on the ballot paper on Election Day.
            </p>
            <p className="mb-2">
              This is different to how things work in every other political party in South Africa, where candidates are
              chosen by political parties, often without any input from the people those candidates are meant to serve.
            </p>
          </dd>
        </div>
        <div className="mb-4">
          <dt className="text-lg leading-6 font-medium text-gray-900">
            How does this differ to other political parties?
          </dt>
          <dd className="text-base text-gray-500">
            <p className="mb-2">
              Every political party uses their own systems to select candidates to run for public office. Often this
              relies on selection committees that promote candidates to the top of party lists based on who they know,
              and not who the community actually wants. The result is candidates that are more loyal to their party than
              the community they are supposed to serve.
            </p>
            <p className="mb-2">
              At ActionSA, we believe that communities should decide for themselves who potentially gets to serve them.{' '}
            </p>
            <p className="mb-2">
              We use a process of direct democracy, whereby eligible candidates participate in a mini-election before
              the Local Government Elections (similar to what is known as a Primary Election in countries like the
              United States), with the successful candidate representing ActionSA on the ballot paper on Election Day.
            </p>
          </dd>
        </div>

        <div className="mb-4">
          <dt className="text-lg leading-6 font-medium text-gray-900">Need assistance?</dt>
          <dd className="text-base text-gray-500">
            Contact <a href="mailto:vote@actionsa.org.za">vote@actionsa.org.za</a> for assistance.
          </dd>
        </div>
        <div className="mt-2">
          <div className="inline-flex rounded-md shadow">
            <a
              href="https://www.actionsa.org.za/voter-faq/"
              target={'_blank'}
              className="inline-flex items-center justify-center px-2 py-1 border border-transparent text-base font-medium rounded-md text-white bg-green hover:bg-green-500"
            >
              View FAQs
              <svg
                className="-mr-1 ml-3 h-5 w-5 text-white"
                x-description="Heroicon name: solid/external-link"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path>
                <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path>
              </svg>
            </a>
          </div>
        </div>
      </dl>
    </LayoutBox>
  )
}
