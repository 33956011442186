import { Button } from '@plusplusminus/plusplusdash'
import { useState } from 'react'
import saveFile from 'save-as-file'

interface DownloadFileProps {
  applicationId: string
  file: string
}

export const DownloadFile: React.FC<DownloadFileProps> = ({ applicationId, file }) => {
  const [loading, setLoading] = useState(false)
  const downloadReport = async () => {
    setLoading(true)

    const url = `${process.env.REACT_APP_API}/applications/files/${applicationId}/${file}`

    await window
      .fetch(url, {
        method: 'GET',
        credentials: 'include'
      })
      .then((response) => {
        if (response.ok) {
          return response.blob()
        }
      })
      .then((result: any) => {
        setLoading(false)
        if (result) {
          saveFile(result, file)
        }
      })
      .catch((error) => {
        setLoading(false)
      })
  }
  return (
    <Button variant="primary" colorScheme="green" onClick={downloadReport} disabled={loading}>
      Download
    </Button>
  )
}
