import React from 'react'
import { Router } from '@reach/router'
import './App.css'
import { Login } from './pages/Login'
import { Dashboard } from './pages/Dashboard'
import { PrivateRoute } from './components/Auth/PrivateRouter'
import { AdminPrivateRoute } from './components/Auth/AdminPrivate'
import { VerifyToken } from './components/Auth/MagicLink'
import { ActivateLink } from './components/Auth/ActivateLink'
import { ApplicationContainer } from './components/Application/ApplicationContainer'
import { Petition } from './pages/Petition'
import { Registration } from './pages/Registration'
import { VoterRegistration } from './pages/Registration/VoterRegistration'
import { Profile } from './pages/Profile'
import { RegisteredConfirmation } from './pages/Petition/RegisteredConfirmation'
import { PetitionConfirmation } from './pages/Petition/PetitionConfirmation'
import { CandidateRegistration } from './pages/Registration/CandidateRegistration'
import { CandidateConfirmation } from './pages/Registration/CandidateConfirmation'
import { ApplicationsContainer } from './pages/Applications'
import { ReportsContainer } from './pages/Reports'
import { UserContainer } from './components/User/UserContainer'
import { EditUser } from './components/User/ui/UserEdit'
import { Petitions } from './components/Petitions'
import { AdminPetitions } from './components/Petitions/AdminPetitions'
import { PetitionSubmissions } from './components/Petitions/ui/PetitionSubmissions'

import { VerifyEmail } from './components/Auth/ConfirmEmail'
import { Logout } from './pages/Login/Logout'
import { Registered } from './pages/Registration/ui/Registered'

const App: React.FC = () => {
  return (
    <Router>
      <Registration path="/" />
      <Login path="/login" />
      <Logout path="/logout" />

      <Registration path="/register" />

      <CandidateRegistration path="/register/candidate" />
      <CandidateConfirmation path="/register/candidate/confirm" />

      <VoterRegistration path="/register/voter" />
      <RegisteredConfirmation path="/register/voter/confirm" />
      <Registered path={'/register/voter/login'} />

      <ActivateLink path="/activate/:id" />
      <VerifyToken path="/verify/:id" />
      <VerifyEmail path="/verifyEmail/:id" />

      <Petition path="/petition/:petitionId" />
      <PetitionConfirmation path="/petition/:petitionId/confirm" />
      <RegisteredConfirmation path="/petition/:petitionId/register" />

      <PrivateRoute component={PetitionSubmissions} path="/petition/:petitionId/submissions/" />

      <PrivateRoute component={Dashboard} path="/dashboard" />
      <PrivateRoute component={Profile} path="/profile" />
      <PrivateRoute component={ApplicationContainer} path="/application/:applicationId" />
      <PrivateRoute component={Petitions} path="/petitions" />
      <AdminPrivateRoute component={ApplicationsContainer} path="/applications" />
      <AdminPrivateRoute component={ApplicationsContainer} path="/applications/:status" />
      <AdminPrivateRoute component={ReportsContainer} path="/reports" />
      <AdminPrivateRoute component={UserContainer} path="/users" />
      <AdminPrivateRoute component={EditUser} path="/users/:id" />
      <AdminPrivateRoute component={AdminPetitions} path="/petitions/admin" />
    </Router>
  )
}

export default App
