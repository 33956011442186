import React, { useState } from 'react'
import { SideNav, Box, NavItem } from '@plusplusminus/plusplusdash'
import logo from '../../logo.png'
import { Link } from '@reach/router'
import { useUserQuery } from '../../hooks/useUserQuery'

export const Layout: React.FC = (props) => {
  const { loading, data, error } = useUserQuery()
  const [isOpen, setOpen] = useState(false)

  const hasAccess = data.me && data.me.role === 'ADMIN'

  return (
    <Box className="h-screen flex overflow-hidden bg-white">
      <div
        className="fixed inset-0 flex z-40 lg:hidden"
        role="dialog"
        aria-modal="true"
        style={{ display: isOpen ? 'flex' : 'none' }}
      >
        <div className="fixed inset-0 bg-gray-600 bg-opacity-75" aria-hidden="true"></div>

        <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
          <div className="absolute top-0 right-0 -mr-12 pt-2">
            <button
              onClick={() => setOpen(false)}
              className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              <span className="sr-only">Close sidebar</span>

              <svg
                className="h-6 w-6 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <div className="flex-shrink-0 flex items-center px-4">
            <img src={'https://www.actionsa.app/images/actionSA_logo.png'} className="w-40 h-auto object-contain" />
          </div>
          <div className="mt-5 flex-1 h-0 overflow-y-auto">
            <nav className="px-2">
              <Link to="/dashboard">
                <NavItem>{hasAccess ? 'My Applications' : 'Applications'}</NavItem>
              </Link>
              {hasAccess ? <AdminMenu loading={loading} error={error} /> : null}
              <Link to="/profile">
                <NavItem>Profile</NavItem>
              </Link>
              <a href="mailto:vote@actionsa.org.za?subject=Candidates App Support">
                <NavItem>Help</NavItem>
              </a>
              <Link to="/logout">
                <NavItem>Logout</NavItem>
              </Link>
              <AdminMenu loading={loading} error={error} />
            </nav>
          </div>
        </div>

        <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
      </div>

      <div className="hidden lg:flex lg:flex-shrink-0">
        <div className="flex flex-col w-64 border-r border-gray-200 pt-5 pb-4 bg-gray-100">
          <div className="flex items-center flex-shrink-0 px-6">
            <img src={'https://www.actionsa.app/images/actionSA_logo.png'} className="w-40 h-auto object-contain" />
          </div>

          <div className="h-0 flex-1 flex flex-col overflow-y-auto">
            <nav className="px-3 mt-6">
              <div className="space-y-1">
                <Link to="/dashboard">
                  <NavItem>{hasAccess ? 'My Applications' : 'Applications'}</NavItem>
                </Link>
                {hasAccess ? <AdminMenu loading={loading} error={error} /> : null}
                <Link to="/profile">
                  <NavItem>Profile</NavItem>
                </Link>
                <a href="mailto:vote@actionsa.org.za?subject=Candidates App Support">
                  <NavItem>Help</NavItem>
                </a>
                <Link to="/logout">
                  <NavItem>Logout</NavItem>
                </Link>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <Box className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:hidden">
          <button
            onClick={() => setOpen(true)}
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 lg:hidden"
          >
            <span className="sr-only">Open sidebar</span>
            <svg
              className="h-6 w-6"
              x-description="Heroicon name: outline/menu-alt-1"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h8m-8 6h16"></path>
            </svg>
          </button>
          <div className="flex items-center flex-shrink-0 px-6">
            <img src={'https://www.actionsa.app/images/actionSA_logo.png'} className="w-40 h-auto object-contain" />
          </div>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">{props.children}</main>
      </Box>
    </Box>
  )
}

interface AdminMenuProps {
  loading: boolean
  error: any
}

const AdminMenu: React.FC<AdminMenuProps> = ({ loading, error }) => {
  if (loading || error) return null
  return (
    <>
      <Link to="/applications">
        <NavItem>All Applications</NavItem>
      </Link>
      <Link to="/reports">
        <NavItem>Reports</NavItem>
      </Link>
      <Link to="/users">
        <NavItem>Users</NavItem>
      </Link>
    </>
  )
}
