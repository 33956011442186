import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { Box, Table, Loader, Alert } from '@plusplusminus/plusplusdash'

interface Submissions {
  id: string
}

interface Application {
  id: string
  location: { municipality: { name: string } }
  submissions: Array<Submissions>
  isArchived: boolean
  application: { template: { name: string } }
  candidate: { id: string }
}

interface ApplicationListItems {
  items: Array<Application>
  newTab?: boolean
}

const TABLE_SHAPE = [
  { label: 'Application', key: 'application', isSortable: true },
  { label: 'Municipality', key: 'location', isSortable: true },
  { label: 'Submissions', key: 'submissions', isSortable: true },
  { label: 'Actions', key: 'actions', isSortable: true }
]

export const PetitionList: React.FC<ApplicationListItems> = ({ items }) => {
  return (
    <Box>
      <Table shape={TABLE_SHAPE}>
        {items.map((petition) => {
          return (
            <Table.Row key={petition.id}>
              <Table.Cell className="font-medium text-gray-900">{petition.application.template.name}</Table.Cell>
              <Table.Cell className="font-medium text-gray-900">{petition.location.municipality.name}</Table.Cell>
              <Table.Cell className="font-medium text-gray-900">{petition.submissions.length}</Table.Cell>
              <Table.Cell className="font-medium text-gray-900">
                <div className="flex space-x-3">
                  {petition?.id && (
                    <>
                      <a className="underline" target={'_blank'} href={`/petition/${petition.id}`}>
                        View Petition
                      </a>
                      <a className="underline" target={'_blank'} href={`/petition/${petition.id}/submissions`}>
                        View Submissions
                      </a>
                    </>
                  )}
                  {petition?.candidate?.id && (
                    <a className="underline" target={'_blank'} href={`/users/${petition.candidate.id}`}>
                      View Owner
                    </a>
                  )}
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table>
    </Box>
  )
}
