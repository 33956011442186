import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { Layout } from '../Layout'
import { HeaderWithActions } from '../../components/Header/HeaderWithActions'
import { PetitionList } from './ui/PetitionList'
import { usePagination } from '../../hooks/usePagaination'
import { Box, Table, Loader, Alert } from '@plusplusminus/plusplusdash'

export const AdminPetitions: React.FC = () => {
  const [page, offset, limit, nextPage, prevPage] = usePagination(50)
  const { data, loading, error, refetch, previousData } = useQuery(PETITIONS_QUERY, {
    variables: {
      pagination: { limit, sort: 'ASC', page }
    }
  })

  if (loading) return null

  const tableData = data || previousData

  return (
    <Layout>
      <Box className="flex flex-col">
        <HeaderWithActions title="Petitions" actions={[]} />
        {error ? (
          <Alert type="error">
            <Alert.Heading>There was an error fetching petitions</Alert.Heading>
          </Alert>
        ) : !previousData && loading ? (
          <div className="flex flex-auto justify-center items-center">
            <Loader isActive={true} />
          </div>
        ) : tableData ? (
          <Box>
            <PetitionList items={tableData.searchPetitions.docs} />
            <div className="flex justify-end py-4 px-8">
              <div className="w-16">
                {tableData.searchPetitions.hasPrevPage && (
                  <a className="underline text-md cursor-pointer" onClick={() => prevPage()}>
                    Prev
                  </a>
                )}
              </div>
              <span className="w-16 text-md mx-4">Page {page}</span>
              <div className="w-16">
                {tableData.searchPetitions.hasNextPage && (
                  <a className="underline text-md cursor-pointer" onClick={() => nextPage()}>
                    Next
                  </a>
                )}
              </div>
            </div>
          </Box>
        ) : null}
      </Box>
    </Layout>
  )
}

const PETITIONS_QUERY = gql`
  query getPetitions($pagination: PaginationArgs!) {
    searchPetitions(pagination: $pagination) {
      totalDocs
      limit
      totalPages
      page
      pagingCounter
      hasPrevPage
      hasNextPage
      prevPage
      nextPage
      docs {
        id
        candidate {
          id
          email
          firstName
          lastName
        }
        submissions {
          id
        }
        isArchived
        application {
          template {
            name
          }
        }
        location {
          name
          municipality {
            name
          }
        }
      }
    }
  }
`
