import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { ApplicationList } from './ ui/ApplicationList'
import { Redirect } from '@reach/router'
import { Box, Button } from '@plusplusminus/plusplusdash'
import { RegisterButton } from './ ui/RegisterButton'

export const Applications: React.FC = () => {
  const { data, loading, refetch } = useQuery(APPLICATIONS_QUERY)

  if (loading) return null

  const hasWard = data.applications.some(
    (application: any) => application.template.name === 'Ward Candidate Registration'
  )
  const hasPr = data.applications.some((application: any) => application.template.name === 'PR Candidate Registration')
  const hasMayor = data.applications.some(
    (application: any) => application.template.name === 'Mayoral Candidate Registration'
  )

  return (
    <>
      <ApplicationList items={data.applications} />
      <Box className={'p-5'}>
        
        <RegisterButton template="candidate" title="Register as Ward Candidate" className="mr-2" refetch={refetch} />
        
      </Box>

    </>
  )
}

const APPLICATIONS_QUERY = gql`
  query getApplications {
    applications {
      id
      owner {
        email
        firstName
        lastName
      }
      template {
        name
      }
      status
    }
  }
`
