import { useMutation } from '@apollo/client'
import { RouteComponentProps, useNavigate } from '@reach/router'
import gql from 'graphql-tag'
import { useEffect } from 'react'

export const Logout: React.FC<RouteComponentProps> = () => {
  const [logout] = useMutation(LOGOUT)
  const navigate = useNavigate()
  useEffect(() => {
    logout().then(() => {
      navigate('/login')
    })
  }, [])

  return null
}

const LOGOUT = gql`
  mutation logout {
    logout
  }
`
