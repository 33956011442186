import { useMutation } from '@apollo/client'
import { RouteComponentProps, useNavigate } from '@reach/router'
import gql from 'graphql-tag'
import { useState } from 'react'
import { Alert } from '../../components/Alert'
import { LayoutBox } from '../../components/LayoutBox'
import { AddressState } from '../../hooks/useAddressSearch'
import { useOtpVerify } from '../../hooks/useOtpVerify'
import { InputValidator } from '../../services/validate'
import { OtpVerification } from './ui/OtpVerification'
import { RegistrationForm } from './ui/RegistrationForm'
import { VoterRegistrationForm } from './ui/VoterRegistrationForm'

interface RegisterFormData {
  firstName: string
  lastName: string
  cellphone: string
  email: string
  idnumber: string
  address?: AddressState
}

interface OtpFormData {
  otp: string
}

interface UserData {
  token: string
  id: string
}

export const VoterRegistration: React.FC<RouteComponentProps> = () => {
  const navigate = useNavigate()
  const [step, setStep] = useState('form')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({ message: '', action: '' })
  const [user, setUser] = useState({ id: '', token: '' })
  const form = useOtpVerify({
    activate: true,
    onSuccess: ({ data }: any) => {
      navigate(`/register/voter/${data.verifyOtp.redirect}`)
    },
    onError: (err: string) => {
      console.log(err)
    }
  })

  const [register] = useMutation(REGISTER_USER)

  const onRegister = (submission: RegisterFormData) => {
    const { address, ...data } = submission
    setLoading(true)
    setError({
      message: '',
      action: ''
    })

    const input: any = {
      firstName: data.firstName.trim(),
      lastName: data.lastName.trim(),
      idnumber: data.idnumber.trim(),

      cellphone: new InputValidator(data.cellphone).formatCellphone('E.164'),
      meta: {
        templateRoles: ['voter'],
        address: address || null
      }
    }

    if (!!data.email) {
      input.email = data.email.toLowerCase().trim()
    }
    register({
      variables: {
        input
      }
    })
      .then(({ data }) => {
        setLoading(true)
        setStep('otp')
        setUser(data.register)
      })
      .catch((err) => {
        setLoading(false)
        if (err.message === 'User already exists.') {
          setError({ message: 'Account already registered', action: 'login' })
        } else {
          setError({
            message: 'Unexpected error has occured please contact vote@actionsa.org.za for assistance.',
            action: ''
          })
        }
      })
  }

  const getWizardComponent = (step: string) => {
    switch (step) {
      case 'form':
        return <VoterRegistrationForm onSubmit={onRegister} loading={loading} />

      case 'otp':
        return <OtpVerification form={form} token={user.token} />
    }
  }

  return (
    <LayoutBox
      title="Registering to participate in ActionSA’s Candidate Elections is easy."
      description={'It doesn’t require ActionSA membership and you will be done in no time.'}
      formTitle="Voter Registration"
      showLogo={true}
    >
      {getWizardComponent(step)}
      {error.message ? <Alert message={error.message} action={'login'} title={'Error'} color="red" /> : null}

      <div className="mt-8">
        <dt className="text-sm font-medium text-gray-900">Need assistance?</dt>
        <dd className="text-sm text-gray-500">
          Email <a href="mailto:vote@actionsa.org.za">vote@actionsa.org.za</a>.
        </dd>
      </div>
      <div className="mt-2">
        <div className="inline-flex rounded-md shadow">
          <a
            href="https://www.actionsa.org.za/voter-faq/"
            target={'_blank'}
            className="inline-flex items-center justify-center px-2 py-1 border border-transparent text-base font-medium rounded-md text-white bg-green hover:bg-green-500"
          >
            View FAQs
            <svg
              className="-mr-1 ml-3 h-5 w-5 text-white"
              x-description="Heroicon name: solid/external-link"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path>
              <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path>
            </svg>
          </a>
        </div>
      </div>
    </LayoutBox>
  )
}

const REGISTER_USER = gql`
  mutation registerUser($input: CreateAuthInput!) {
    register: registerWithCellphoneVerification(input: $input) {
      id
      token: otpFingerprint
    }
  }
`
