import React from 'react'
import { RouteComponentProps, Redirect } from '@reach/router'
import { useUserQuery } from '../../hooks/useUserQuery'

interface IAdminPrivateRoute extends RouteComponentProps {
  component: React.ElementType
}

const AdminPrivateRoute: React.FC<IAdminPrivateRoute> = ({ component: Component, ...props }) => {
  const { loading, data, error } = useUserQuery()

  if (loading) return <div>Loading</div>

  if (error) return <Redirect noThrow to={'/login'} />

  console.log(data.me)
  const hasAccess = data.me && data.me.role === 'ADMIN'

  return hasAccess ? <Component {...props} {...data.me}/> : <Redirect noThrow to={'/login'} />
}

export { AdminPrivateRoute }
